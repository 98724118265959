export class FunctionUrls {
  static readonly dataApi = {
    getAllUsers: '/api/GetUsers',
    getRoles: '/api/GetRoles',
    getUser: '/api/GetUser',
    getLoggedInUser: '/api/getLoggedInUser',
    getUsersFromAD: '/api/GetUsersFromAD',
    saveUser: '/api/SaveUser',
    resendInvite: '/api/ResendInvite',
    getPermissions: '/api/GetPermissions',
    savePermissions: '/api/SavePermissions',

    createClient: '/api/createClient',
    deleteClient: '/api/deleteClient',
    getClients: '/api/GetClients',
    updateDateFormat: '/api/updateClientDateFormat',

    createProjectType: '/api/createProjectType',
    deleteProjectType: '/api/deleteProjectType',
    getProjectTypes: '/api/getProjectTypes',

    createPropertyType: '/api/createPropertyType',
    deletePropertyType: '/api/deletePropertyType',
    getPropertyTypes: '/api/getPropertyTypes',

    createClientLink: '/api/createClientLink',
    deleteClientLink: '/api/deleteClientLink',
    getClientLinks: '/api/getClientLinks',

    createClientGroup: '/api/createClientGroup',
    deleteClientGroup: '/api/deleteClientGroup',
    getClientGroups: '/api/getClientGroups',

    getClientAdminConfig: '/api/getClientAdminConfig',
    creatClientAdminConfig: '/api/createClientAdminConfig',

    createClientSubGroup: '/api/createClientSubGroup',
    getClientSubGroups: '/api/getClientSubGroups',

    getUOM: '/api/getUnitOfMeasurements',
    getCountries: '/api/getCountries',
    getStates: '/api/getStates',
    getCurrencies: '/api/getCurrencies',

    getCommissionRequests: '/api/getCommissionRequests',
    getCommissionRequest: '/api/getCommissionRequest',
    getRequestStatus: '/api/getRequestStatus',
    createCommissionRequest: '/api/createRequest',
    getCommissionRequestAudits: '/api/getCommissionRequestAudits',
    documentUpload: '/api/documentUpload',
    getUploadedDocument: '/api/getCommissionFile',
    downloadDocument: '/api/downloadFile',
    deleteDocument: '/api/deleteDocument',
    getUsers: '/api/getUsers',
    getExcelData: '/api/getExcelData',
    trianaJWTlogin: 'https://triana.cushwake.com/Account/ValidateCredential'
  };
}
